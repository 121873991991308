import { create } from 'zustand'

type State = {
  isActive: boolean
}

type Action = {
  toggleMenu: () => void
}

export const useActiveMenu = create<State & Action>((set) => ({
  isActive: false,
  toggleMenu: () => set((state: any) => ({ isActive: !state.isActive })),
}))
