import React, { useEffect } from 'react'

import { Header } from 'src/components'
import { ReferralsList } from '../Referrals/components'

import styles from './ListOfReferrals.module.scss'

export type ReferralType = {
  name: string
  amount: number
  id: number
}
let REFERRALS: Array<ReferralType> = [
  { name: '@super_user2424345654545144546', amount: 1000000.45, id: 1 },
  { name: '@super_puper_user12081394762239475193465', amount: 1000000.45, id: 2 },
  { name: '@masha_88789', amount: 988, id: 3 },
  { name: '@masha_88789', amount: 722, id: 4 },
  { name: '@masha_88789', amount: 523, id: 5 },
  { name: '@masha_88789', amount: 128, id: 6 },
  { name: '@super_user2424345654545144546', amount: 1000000.45, id: 7 },
  { name: '@super_puper_user12081394762239475193465', amount: 1000000.45, id: 222 },
  { name: '@masha_88789', amount: 988, id: 8 },
  { name: '@masha_88789', amount: 722, id: 9 },
  { name: '@masha_88789', amount: 523, id: 10 },
  { name: '@masha_88789', amount: 128, id: 11 },
  { name: '@super_user2424345654545144546', amount: 1000000.45, id: 12 },
  { name: '@super_puper_user12081394762239475193465', amount: 1000000.45, id: 13 },
  { name: '@masha_88789', amount: 988, id: 14 },
  { name: '@masha_88789', amount: 722, id: 15 },
  { name: '@masha_88789', amount: 523, id: 16 },
  { name: '@masha_88789', amount: 128, id: 17 },
  { name: '@super_user2424345654545144546', amount: 1000000.45, id: 18 },
  { name: '@super_puper_user12081394762239475193465', amount: 1000000.45, id: 19 },
  { name: '@masha_88789', amount: 988, id: 20 },
  { name: '@masha_88789', amount: 722, id: 21 },
  { name: '@masha_88789', amount: 523, id: 22 },
  { name: '@masha_88789', amount: 128, id: 23 },
  { name: '@super_user2424345654545144546', amount: 1000000.45, id: 24 },
  { name: '@super_puper_user12081394762239475193465', amount: 1000000.45, id: 25 },
  { name: '@masha_88789', amount: 988, id: 26 },
  { name: '@masha_88789', amount: 722, id: 27 },
  { name: '@masha_88789', amount: 523, id: 28 },
  { name: '@masha_88789', amount: 128, id: 29 },
]

const ListOfReferrals = () => {
  useEffect(() => {
    document.body.classList.add(styles['white-background'])

    return () => {
      document.body.classList.remove(styles['white-background'])
    }
  })

  return (
    <>
      <Header path='..' variant='settings' setting='Мої реферали' color='black' />

      <ReferralsList className={styles.list} referralsList={REFERRALS} />
    </>
  )
}

export default ListOfReferrals
