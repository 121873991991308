import { createBrowserRouter } from 'react-router-dom'

import {
  Layout,
  IntroPage,
  TicketsStore,
  // MainPage,
  // Welcome,
  // Error,
  // HistoryOfTransactions,
  // BottlesPage,
  // BanksParticipation,
  // CreateBottle,
  // Notifications,
  // BottlePage,
  // ParticipantsPage,
  // Referrals,
  // ListOfReferrals,
} from 'src/pages'
// import { FirstPage, SecondPage, ThirdPage, ConfirmationPage, StatusPage } from 'src/pages/CreateBottle/components'
// import { AttachmentToBottle } from 'src/pages/Bottle/components'

export const router = createBrowserRouter([
  // welcome page
  // {
  //   path: '/welcome',
  //   element: <Welcome />,
  // },
  // main pages
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <IntroPage />,
      },
      {
        path: '/tickets',
        element: <TicketsStore />,
      },
      // {
      //   path: '/',
      //   element: <MainPage />,
      // },
      // {
      //   path: '/bottles',
      //   element: <BottlesPage />,
      // },
      // {
      //   path: '/referrals',
      //   element: <Referrals />,
      // },
    ],
  },
  // bottle page
  //   {
  //     path: '/referrals-list',
  //     element: <ListOfReferrals />,
  //   },
  //   {
  //     path: '/bottles/:id',
  //     element: <BottlePage />,
  //   },
  //   {
  //     path: '/bottles/:id/attachment',
  //     element: <AttachmentToBottle />,
  //   },
  //   {
  //     path: '/bottles/:id/participants',
  //     element: <ParticipantsPage />,
  //   },
  //   // create bottle page
  //   {
  //     path: '/create-bottle',
  //     element: <CreateBottle />,
  //     children: [
  //       {
  //         path: '/create-bottle/step-1',
  //         element: <FirstPage />,
  //       },
  //       {
  //         path: '/create-bottle/step-2',
  //         element: <SecondPage />,
  //       },
  //       {
  //         path: '/create-bottle/step-3',
  //         element: <ThirdPage />,
  //       },
  //       {
  //         path: '/create-bottle/confirmation',
  //         element: <ConfirmationPage />,
  //       },
  //     ],
  //   },
  //   {
  //     path: '/create-bottle/created',
  //     element: <StatusPage status='confirmation' />,
  //   },
  //   {
  //     path: '/create-bottle/error',
  //     element: <StatusPage status='error' />,
  //   },
  //   // history of transactions page
  //   {
  //     path: '/transactions',
  //     element: <HistoryOfTransactions />,
  //   },
  //   // notifications page
  //   {
  //     path: '/notifications',
  //     element: <Notifications />,
  //   },
  //   // error page
  //   {
  //     path: '/banks-participation',
  //     element: <BanksParticipation />,
  //   },
  //   {
  //     path: '/error',
  //     element: (
  //       <Error
  //         variant='generalError'
  //         title='Щось пішло не так...'
  //         description='Сталася помилка. Спробуйте пізніше або зверніться до підтримки.'
  //       />
  //     ),
  //   },
])
