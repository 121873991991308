import { useEffect, useState } from 'react'
import { ticketsStatistics } from 'src/axious/ticketsStatistics'

import { useTickets } from 'src/zustand/stores/tickets'

export const useTicketsStatistics = (initDataRaw: string) => {
  const [data, setData] = useState<{ amount_of_stars: number; amount_of_tickets: number; ticketsList: number }>()

  const amount_of_stars = useTickets((state) => state.amount_of_stars)
  const amount_of_tickets = useTickets((state) => state.amount_of_tickets)
  const tickets = useTickets((state) => state.ticketsList)

  const setTicketsStatistics = useTickets((state) => state.setTicketsStatistics)

  useEffect(() => {
    if (!amount_of_stars || !amount_of_tickets || !tickets) {
      ticketsStatistics(initDataRaw).then(({ data }) => {
        setData(data)
        setTicketsStatistics({
          amount_of_stars: data.amount_of_stars,
          amount_of_tickets: data.amount_of_tickets,
        })
      })
    } else {
      setData({ amount_of_stars, amount_of_tickets, ticketsList: tickets })
    }
  }, [amount_of_stars, amount_of_tickets, tickets, setTicketsStatistics])

  return { data }
}
