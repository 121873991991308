import axios from './axios'
import { AxiosRequestConfig } from 'axios'

interface IConfig extends AxiosRequestConfig<any> {}

export async function callApi(config: IConfig): Promise<any> {
  const request = async (refreshed?: boolean): Promise<any> => {
    // const tryRefreshToken = async (err: any) => {
    //   try {
    //     const resp = await axios.post('/auth/refresh')
    //     return request(true)
    //   } catch (e: any) {
    //     throw err?.response || err
    //   }
    // }

    try {
      const res = await axios.request({
        ...config,
        headers: {
          'Content-Type': 'application/json',
          decompress: false,
          ...config.headers,
        },
      })

      // console.log('res', res)

      return res.data
    } catch (e: any) {
      // if (e?.response?.status === 401 && !config.url?.includes('auth/login')) {
      //   if (!refreshed) return tryRefreshToken(e)
      //   console.error('e?.response?.status', e?.response?.status)
      //   throw e?.response || e
      // }

      throw e?.response || e
    }
  }

  return request()
}
