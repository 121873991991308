import { useEffect, useState } from 'react'

import { auth } from 'src/axious/auth'
import { useGlobalStore } from 'src/zustand/providers/global-store-provider'
import { useTickets } from 'src/zustand/stores/tickets'

export const useAuth = () => {
  const [authData, setAuthData] = useState<any>()
  const user = useGlobalStore((store) => store.user)
  const setUser = useGlobalStore((store) => store.setUser)
  const setTicketsStatistics = useTickets((state) => state.setTicketsStatistics)

  useEffect(() => {
    if (!user) {
      auth('').then(({ data }) => {
        setAuthData(data)
        setUser(data)
        setTicketsStatistics({ ticketsList: data._count.TicketsList })
      })
    } else {
      setAuthData(user)
    }
  }, [user, setUser])

  return { authData }
}
