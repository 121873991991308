import { useState, useEffect } from 'react'

export const useTimer = (text: string): string => {
  const [timeLeft, setTimeLeft] = useState<string>(text)

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    return `${hours}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  useEffect(() => {
    const formattedTime = text.split(':')

    const hours = parseInt(formattedTime[0])
    const minutes = parseInt(formattedTime[1])
    const seconds = parseInt(formattedTime[2])

    let totalSeconds = hours * 60 * 60 + minutes * 60 + seconds

    const interval = setInterval(() => {
      if (totalSeconds <= 0) {
        clearInterval(interval)
      } else {
        totalSeconds -= 1
        setTimeLeft(formatTime(totalSeconds))
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return timeLeft
}
