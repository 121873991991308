import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useTickets } from 'src/zustand/stores/tickets'

import styles from './TicketsStore.module.scss'

import { ITEMS_TICKETS_STORE } from './items'

import ticketViolet from 'src/assets/images/ticket-violet.png'
import { createInvoiceLink } from 'src/axious/createInvoiceLink'
import { tickets } from 'src/axious/tickets'

const TicketsStore = () => {
  const navigate = useNavigate()
  const setTicketsStatistics = useTickets((state) => state.setTicketsStatistics)
  const ticketsList = useTickets((state) => state.ticketsList) || 0

  // Инициализация данных пользователя
  const initData = window.Telegram.WebApp?.initDataUnsafe

  console.log('initData', initData?.user?.id)

  const handleClickOnItem = async (numbers: number) => {
    try {
      // Получение ссылки на оплату с сервера
      const invoiceLink = await createInvoiceLink(numbers)

      if (invoiceLink) {
        // Открытие платежного интерфейса Telegram
        window.Telegram.WebApp.openInvoice(invoiceLink, (status: 'paid' | 'cancelled' | 'failed') => {
          console.log('Payment status:', status)

          if (status === 'paid') {
            console.log('Payment successful! Updating tickets...')

            // Обновление статистики тикетов после успешной оплаты
            tickets(numbers).then(({ data }: { data: any }) => {
              console.log('Tickets updated:', data)
            })
            setTicketsStatistics({ ticketsList: ticketsList - numbers })
            navigate('/')
          } else {
            console.log('Payment not completed or cancelled.')
          }
        })
      } else {
        console.error('Failed to get invoice link.')
      }
    } catch (error) {
      console.error('Error during payment process:', error)
    }
  }

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {ITEMS_TICKETS_STORE.map((item) => (
          <li onClick={() => handleClickOnItem(item.numbers)} className={styles.item} key={item.id}>
            <div className={styles.item_numbers}>
              <img src={ticketViolet} alt='Violet ticket' />
              <p>x{item.numbers}</p>
            </div>

            <p className={styles.item_amount}>{item.amount} Stars</p>
          </li>
        ))}
      </ul>

      <Link to='..' className={styles.back}>
        Назад
      </Link>
    </div>
  )
}

export default TicketsStore
