import { callApi } from './config/callApi'

export const ticketsStatistics = async (initDataRaw: string): Promise<any> => {
  return callApi({
    method: 'get',
    url: `/tickets/all`,
    headers: {
      Authorization: `tma ${initDataRaw}`,
    },
  })
}
