import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Icon, Timer } from 'src/components/ui'
import { formattedText } from 'src/utils/formattedText'
import { useAuth } from 'src/hooks/useAuth'
import { useGlobalStore } from 'src/zustand/providers/global-store-provider'

import styles from './IntroPage.module.scss'

import bankImage from 'src/assets/images/jar_img.png'
import ticketWhite from 'src/assets/images/ticket-white.png'
import ticketViolet from 'src/assets/images/ticket-violet.png'
import { useTicketsStatistics } from 'src/hooks/useTicketsStatistics'
import { useTickets } from 'src/zustand/stores/tickets'

const IntroPage = () => {
  const navigate = useNavigate()
  const user = useGlobalStore((store) => store.user)

  useAuth()
  useTicketsStatistics(`${user?.id}`)

  const amount_of_stars = useTickets((state) => state.amount_of_stars) || 0
  const amount_of_tickets = useTickets((state) => state.amount_of_tickets) || 0
  const tickets = useTickets((state) => state.ticketsList)

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_intro}>
        <img className={styles.img_bank} src={bankImage} alt='Bank with money' />

        <div className={styles.wrapper_info}>
          <div className={styles.wrapper_item}>
            <p className={styles['wrapper_item-subtitle']}>Призові</p>
            <div className={styles['wrapper_item-title']}>
              <Icon name='star' />
              <p>{formattedText(amount_of_stars)}</p>
            </div>
          </div>
          <div className={styles.wrapper_item}>
            <p className={styles['wrapper_item-subtitle']}>Квитків</p>
            <div className={styles['wrapper_item-title']}>
              <img src={ticketWhite} alt='white ticket' />
              <p>{formattedText(amount_of_tickets)}</p>
            </div>
          </div>
          <div className={styles.wrapper_item}>
            <p className={styles['wrapper_item-subtitle']}>До кінця</p>
            <div className={styles['wrapper_item-title']}>
              <Timer title='20:15:45' variant='time' className={styles.timer} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.wrapper_action}>
        <p>
          {tickets && `У вас ${tickets} квитків`}
          {!tickets && 'У вас немає квитків'}
        </p>
        <Button onClick={() => navigate('/tickets')} variant='contained' color='white'>
          <img src={ticketViolet} alt='violet ticket' />
          Квитки
        </Button>
      </div>
    </div>
  )
}

export default IntroPage
