import { callApi } from './config/callApi'

export const tickets = async (amount_of_tickets: number): Promise<any> => {
  return callApi({
    method: 'post',
    url: `/tickets`,
    data: {
      amount_of_tickets,
    },
  })
}
