import { create } from 'zustand'

type State = {
  isActive: boolean
  quantityFilters: number
  isReset: boolean
  filters: {
    typesOfDeposits: Array<string>
    amountsOfDeposits: Array<number>
    numbersOfParticipants: Array<string>
    typesOfBottles: Array<string>
  }
}

type Action = {
  toggleOpenFilters: () => void
  addFilter: (filter: string | number, type: string) => void
  addAmountDepositFilter: (filters: Array<number>) => void
  calculationOfQuantityFilters: () => void
  resetFilters: () => void
  setResetFilters: () => void
}

export const useActiveFilters = create<State & Action>((set) => ({
  isActive: false,
  quantityFilters: 0,
  isReset: false,
  filters: {
    typesOfDeposits: [],
    amountsOfDeposits: [],
    numbersOfParticipants: [],
    typesOfBottles: [],
  },
  toggleOpenFilters: () => set((state: any) => ({ isActive: !state.isActive })),
  addFilter: (filter: string | number, type: string) =>
    set((state: any) => {
      if (state.filters[type].includes(filter)) {
        return {
          filters: {
            ...state.filters,
            [type]: state.filters[type].filter((f: string | number) => f !== filter),
          },
        }
      }

      return {
        filters: {
          ...state.filters,
          [type]: [...state.filters[type], filter],
        },
      }
    }),

  addAmountDepositFilter: (filters: Array<number>) =>
    set((state: any) => {
      return { filters: { ...state.filters, amountsOfDeposits: [...filters] } }
    }),

  calculationOfQuantityFilters: () =>
    set((state: any) => {
      return {
        quantityFilters:
          state.filters.typesOfDeposits.length +
          state.filters.amountsOfDeposits.length / 2 +
          state.filters.numbersOfParticipants.length +
          state.filters.typesOfBottles.length,
      }
    }),

  resetFilters: () =>
    set(() => {
      return {
        isReset: true,
        filters: {
          typesOfDeposits: [],
          amountsOfDeposits: [],
          numbersOfParticipants: [],
          typesOfBottles: [],
        },
      }
    }),

  setResetFilters: () =>
    set(() => {
      return { isReset: false }
    }),
}))
