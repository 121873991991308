import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react'
import cn from 'classnames'

import Icon from '../Icon/Icon'
import styles from './Button.module.scss'

interface ButtonPropsType
  extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'ref'> {
  children: ReactNode
  variant: 'contained' | 'outlined' | 'not-active' | 'more'
  color?: 'violet' | 'white' | 'orange'
  opacity?: boolean
  className?: string
  iconType?: 'plus' | 'whiteStar' | 'nextArrow'
}

const Button: FC<ButtonPropsType> = ({ variant, children, className, iconType = '', color, opacity, ...props }) => {
  const classIconType = styles[`button_${iconType}`]

  const buttonClass = cn(styles.button, className, classIconType, {
    [styles[`${variant}`]]: variant,
    [styles[`${color}`]]: color,
    [styles.opacity]: opacity === true,
  })

  return (
    <button className={buttonClass} {...props} disabled={variant === 'not-active'}>
      {iconType === '' ? (
        children
      ) : (
        <>
          <Icon name={iconType} className={styles[`icon_${iconType}`]} />
          <p>{children}</p>
        </>
      )}
    </button>
  )
}

export default Button
