import { create } from 'zustand'

type State = {
  amount_of_stars: number | null
  amount_of_tickets: number | null
  ticketsList: number | null
}

type Action = {
  setTicketsStatistics: (statistics: any) => void
}

export const useTickets = create<State & Action>((set) => ({
  amount_of_stars: null,
  amount_of_tickets: null,
  ticketsList: null,
  setTicketsStatistics: (statistics: any) => set((state: any) => ({ ...state, ...statistics })),
}))
