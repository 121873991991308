import React from 'react'
// import { useLocation } from 'react-router-dom'

import { Outlet } from 'react-router-dom'
// import { Footer, Header } from 'src/components'

import './Layout.scss'

const Layout = () => {
  // const location = useLocation()

  return (
    <>
      {/* {location.pathname !== '/referrals' && <Header variant='main' />} */}
      <main>
        <Outlet />
      </main>
      {/* <Footer /> */}
    </>
  )
}

export default Layout
