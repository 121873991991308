import React, { useEffect } from 'react'
import { useGlobalStore } from './zustand/providers/global-store-provider'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes/router'
import './App.scss'

// import { retrieveLaunchParams } from '@telegram-apps/sdk'
// import { auth } from './axious/auth'

function App() {
  // const user = useGlobalStore((store) => store.user)
  // const setUser = useGlobalStore((store) => store.setUser)
  //
  // console.log('user', user)
  //
  // useEffect(() => {
  //   const { initDataRaw } = retrieveLaunchParams()
  //
  //   if (initDataRaw)
  //     auth(initDataRaw).then(({ data }) => {
  //       setUser(data)
  //     })
  // }, [])

  return <RouterProvider router={router} />
}

export default App
