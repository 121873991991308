import sprite from 'src/assets/sprite.svg'

import s from './Icon.module.scss'

export interface IconProps {
  name: string
  className?: string
}

const Icon = ({ name, className }: IconProps) => {
  return (
    <svg className={`${s.icon} ${s.logo} ${className}`}>
      <use href={`${sprite}#${name}`} />
    </svg>
  )
}

export default Icon
