import React from 'react'

import Icon from '../../Icon'
import { useTimer } from './hooks/useTimer'

import styles from './Timer.module.scss'

interface TimerProps {
  title: string
  className?: string
  variant?: 'time' | 'big' | 'text'
}

const Timer = ({ title, className, variant }: TimerProps) => {
  let timeLeft = useTimer(title)

  if (variant === 'text') {
    timeLeft = title
  }

  return (
    <div className={`${styles.timer} ${className}`}>
      {variant === 'big' ? (
        <Icon name='bigTime' className={styles.big} />
      ) : (
        <Icon name='time' className={styles.small} />
      )}
      <p>{timeLeft}</p>
    </div>
  )
}

export default Timer
