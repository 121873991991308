export const ITEMS_TICKETS_STORE = [
  {
    id: 1,
    numbers: 1,
    amount: 100,
  },
  {
    id: 2,
    numbers: 10,
    amount: 900,
  },
  {
    id: 3,
    numbers: 50,
    amount: 4000,
  },
  {
    id: 4,
    numbers: 100,
    amount: 7000,
  },
]
