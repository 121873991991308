import axios from 'axios'

// @ts-ignore
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  // withCredentials: true,
  paramsSerializer: {
    indexes: null,
  },
})

export default instance
