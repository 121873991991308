import axios from 'axios'

// Создание платежной ссылки
export const createInvoiceLink = async (numbers: number) => {
  const TOKEN = process.env.REACT_APP_BOT_TOKEN
  const TELEGRAM_API = `https://api.telegram.org/bot${TOKEN}`
  const payload = `_${Date.now()}`
  try {
    // Отправка запроса на создание ссылки счета
    const response = await axios.post(`${TELEGRAM_API}/createInvoiceLink`, {
      title: 'Pay for Telegram Stars', // Название платежа
      description: "Test payment - all stars will be refunded (But it's not just that)", // Описание
      payload: payload, // Уникальный payload для идентификации платежа
      provider_token: '', // Пустой для интеграции с Telegram Stars
      currency: 'XTR', // Валюта
      prices: [{ label: 'stars', amount: numbers }], // Список товаров с ценами
    })

    // Возврат ссылки на счет
    return response.data.result
  } catch (error) {
    console.error('Error while creating invoice link:', error)
    throw error
  }
}
